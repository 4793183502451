import React from "react";
import { graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import { mapEdgesToNodes } from "../lib/helpers";
import CallToAction from "../components/Repeating/CTA";

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Homepage.jpg" }
    ) {
      publicURL
    }
    videos: allSanityVideo(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          title
          slug {
            current
          }
          videoPosterImage {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 746)
            }
          }
          videoSource
        }
      }
    }
  }
`;

const Page = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const videoNodes = data && data.videos && mapEdgesToNodes(data.videos);

  return (
    <Layout>
      <SearchEngineOptimization
        title="Video Library | Colorado Lawyer | JBP"
        description="Get access to the video resources of Jorgensen, Brownell & Pepin, P.C., a full-service law firm in Colorado. Schedule a consultation with an attorney today."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pt-12 md:pt-16 pb-20 md:pb-32">
        <div className="container">
          <header className="mb-14 md:mb-20">
            <h1 className="mb-0">Video Library</h1>
          </header>

          <div className="grid md:grid-cols-3 gap-y-10 md:gap-y-16 md:gap-x-6 lg:gap-x-10">
            {videoNodes &&
              videoNodes.length > 0 &&
              videoNodes.map((item, i) => (
                <div key={i}>
                  <AniLink
                    fade
                    to={`/video-center/${item.slug.current}/`}
                    className="group no-underline"
                  >
                    <div className="overflow-hidden mb-5">
                      <GatsbyImage
                        image={item.videoPosterImage.asset.gatsbyImageData}
                        className="w-full transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                      />
                    </div>

                    <div className="heading-five mb-0">{item.title}</div>
                  </AniLink>
                </div>
              ))}
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export default Page;
